<script lang="ts">
  import { useTranslations, type Lang } from "@i18n/index.ts";
  import { onMount } from "svelte";

  export let lang: Lang = "es-CO";
  let isToggleMenu = false;

  const __e = useTranslations(lang);

  function toggleMenu(e: any) {
    e.preventDefault();
    const btn = e.currentTarget as HTMLButtonElement;
    const target = btn.getAttribute("data-collapse-toggle");

    //document.querySelector("#" + target)?.classList.toggle("mobile-hidden");
    document.querySelector("body")?.classList.toggle("menu-open");
    isToggleMenu = !isToggleMenu;
  }

  function showSubmenu(e: any) {
    e.preventDefault();
    const linkMenu = e.currentTarget as HTMLLinkElement;
    const target = linkMenu.getAttribute("data-collapse-menu") || "";
    document.querySelector(target)?.classList.toggle("submenu-open");
  }

  function detectMobile() {
    return window.innerWidth <= 1000 && window.innerHeight <= 600;
  }

  function onresize() {
    //document.querySelector("#menu")?.classList.add("mobile-hidden");
    document.querySelector("body")?.classList.remove("menu-open");
    isToggleMenu = false;
  }

  function onScrollY(event: any) {
    if (!detectMobile()) {
      if (window.scrollY >= 150) {
        document.querySelector("#mainmenu")?.classList.add("menu-sticky");
      } else {
        document.querySelector("#mainmenu")?.classList.remove("menu-sticky");
      }
    }
  }

  onMount(() => {
    window.addEventListener("resize", onresize);
    document.addEventListener("scroll", onScrollY, { passive: true });

    if (detectMobile()) {
      //document.querySelector('.dropdown a')?.classList.add("mobile-hidden");
      document.querySelector("body")?.classList.remove("menu-open");
    }
    
    if (window.scrollY >= 150) {
      document.querySelector("#mainmenu")?.classList.add("menu-sticky");
    } else {
      document.querySelector("#mainmenu")?.classList.remove("menu-sticky");
    }
    
    return () => {
      window.removeEventListener("resize", onresize);
    };
  });
</script>

<!-- <svelte:window bind:scrollY={y} /> -->
<nav id="mainmenu" class="lg:sticky lg:top-0 menu-sticky">
  <div class="logo">
    <a href="/" title="Meridian Group S.A.S">
      <img
        src="/assets/logo.svg"
        class="logo-lg wow fadeInDown"
        alt="Meridian"
        width="152"
        height="130"
      />
      <img
        src="/assets/logo-inline.svg"
        class="logo-sm wow fadeInDown"
        alt="Meridian"
        width="152"
        height="70"
      />
    </a>
  </div>
  <ul class="menu md:flex sm:flex-column items-center" id="menu">
    <!-- <li class="dropdown">
      <a
        href="#"
        on:click|preventDefault={showSubmenu}
        data-collapse-menu="#submenu-services"
        >{__e("nav.products-services")} <span>&#8964;</span></a
      >
      <ul class="dropdown-menu" id="submenu-services">
        <li><a href="/products">{__e("nav.products")}</a></li>
        <li><a href="/services">{__e("nav.services")}</a></li>
      </ul>
    </li> -->

    <!-- <li><a href="/news">{__e("nav.news")}</a></li> -->
    <!-- <li><a href="/blog">{__e("nav.blog")}</a></li> -->
    <li><a href="/about">{__e("nav.about-us")}</a></li>
    <li><a href="/about/team">{__e("nav.about.team")}</a></li>
    <li><a href="/about/our-customers">{__e("nav.about.customers")}</a></li>
   <!--  <li class="dropdown">
      <a
        href="#"
        on:click|preventDefault={showSubmenu}
        data-collapse-menu="#submenu-about"
        >{__e("nav.about-us")} <span>&#8964;</span></a
      >
      <ul class="dropdown-menu" id="submenu-about">
        <li><a href="/about">{__e("nav.about")}</a></li>
        <li><a href="/about/team">{__e("nav.about.team")}</a></li>
        <li><a href="/about/our-customers">{__e("nav.about.customers")}</a></li>
        <li>
          <a href="/about/achievements">{__e("nav.about.achievement")}</a>
        </li>
      </ul> 
    </li>-->
    <li><a href="/contact">{__e("nav.contact")}</a></li>
  </ul>

  <div class="hamburger">
    {#if !isToggleMenu}
      <a
        href="/"
        on:click|preventDefault={toggleMenu}
        data-collapse-toggle="menu"
      >
        <img
          src="/assets/hamburger.svg"
          width="30"
          height="30"
          alt="Toggle Menu"
        />
        {__e("nav.menu")}
      </a>
    {/if}
    {#if isToggleMenu}
      <a
        href="/"
        on:click|preventDefault={toggleMenu}
        data-collapse-toggle="menu"
      >
        <img
          src="/assets/cancel.svg"
          width="30"
          height="30"
          alt={__e("nav.close")}
        />
        {__e("nav.close")}
      </a>
    {/if}
  </div>
</nav>

<style>
  nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 50px 20px 80px;
    transition: all 0.3s ease-in-out;
    z-index: 9998;
  }

  .logo {
    position: relative;
    width: 152px;
    height: 60px;
    transition: all 0.3s ease-in-out;
  }

  .logo a {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
  }
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    min-height: 60px;
    border-bottom: solid 1px #707070;
  }

  .menu > li {
    margin-left: 20px;
  }

  .menu > li:first-child {
    margin-left: 0;
  }

  .menu li a {
    color: #c2c2c2;
    text-decoration: none;
    font-size: 22px;
    line-height: 35px;
    font-weight: 200;
    font-family: "Poppins", sans-serif;
    padding: 10px;
    border-radius: 5px;
  }

  .menu li a:hover {
    color: white;
  }

  .menu .dropdown {
    position: relative;
  }

  .dropdown-menu {
    background-color: #1b1b1b;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 250px;
    z-index: 99;
    opacity: 0;
    height: 0px;
    padding: 0;
    pointer-events: none;
    display: block;
    transition: all 0.3s linear;
    overflow: hidden;
    box-shadow: 2px 2px 2px #000;
    border: solid 1px #2b2b2b;
  }

  .dropdown:hover .dropdown-menu {
    opacity: 1;
    height: auto;
    padding: 10px 0;
    pointer-events: all;
  }

  .dropdown-menu li {
    display: block;
  }

  .dropdown-menu li a {
    color: #c2c2c2;
    text-decoration: none;
    font-size: 22px;
    line-height: 35px;
    font-weight: 200;
    font-family: "Poppins", sans-serif;
    padding: 8px 20px;
    border-radius: 0;
    transition: all 0.3s linear;
  }

  .dropdown-menu li a:hover {
    text-decoration: underline;
    text-decoration-color: #ec5512;
  }

  .hamburger {
    display: none;
    width: 50px;
  }

  .hamburger a {
    font-size: 14px;
    line-height: 30px;
    color: #e4e4e4;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .logo img {
    width: 0;
    height: 0;
    max-width: 152px;
  }

  .logo .logo-lg {
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    height: auto;
    width: 152px;
  }
  .logo .logo-sm {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    height: 0;
    transform: scale(0);
  }

  .menu-sticky {
    background-color: #1b1b1bcc;
    padding: 0px 50px 5px 80px;
    backdrop-filter: blur(10px);
  }

  .menu-sticky .logo {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  .menu-sticky .logo a {
    position: static;
  }

  .menu-sticky .logo-lg {
    height: 0px;
    opacity: 0;
    transform: scale(0);
  }

  .menu-sticky .logo-sm {
    opacity: 1;
    height: auto;
    transform: scale(1);
    width: 152px;
  }

  @media (max-width: 1180px) {
    .menu li a,
    .dropdown-menu li a {
      font-size: 1.3rem;
      line-height: 1.8rem;
      padding: 20px 5px;
    }
  }

  @media (max-width: 1104px) {
    .menu li a,
    .dropdown-menu li a {
      font-size: 1.1rem;
      line-height: 1.1rem;
      padding: 20px 5px;
    }
  }

  @media (max-width: 1000px) {

    .dropdown a span {
      display: none;
    }

    .dropdown:hover .dropdown-menu {
      opacity: 0;
      height: 0px;
      pointer-events: none;
      transition: all 100ms ease-in-out;
      transform: translateY(-20px);
    }

    .dropdown-menu {
      opacity: 0;
      height: 0px;
      pointer-events: none;
      transition: all 100ms ease-in-out;
      transform: translateY(-20px);
    }

    nav {
      padding: 20px;
    }
    .logo .logo-lg {
      height: 0px;
      opacity: 0;
      transform: scale(0);
    }

    .logo .logo-sm {
      opacity: 1;
      height: auto;
      transform: scale(1);
      width: 152px;
    }

    .logo a {
      height: 60px;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .hamburger {
      display: block;
    }
    .menu {
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      background-color: #2a2a2a;
      padding: 20px 20px 40px 20px;
      border-bottom: none;
      z-index: 999;
      flex-direction: column;
      height: calc(100vh - 100px);
      overflow-y: auto;
      position: fixed;
      transform: translateY(-150vh);
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }

    .menu li {
      display: block;
      border-bottom: 1px solid #c2c2c2;
      margin-left: 0px;
    }

    .menu li a,
    .dropdown-menu li a {
      display: block;
      color: #c2c2c2;
      font-size: 1.7rem;
      font-weight: 300;
      line-height: 2.2rem;
      padding: 20px 5px;
      text-align: center;
    }
    .dropdown-menu {
      position: static;
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0;
    }

    .dropdown-menu li {
      border-bottom: none;
    }

    .hamburger img {
      width: 30px;
      height: 30px;
    }
  }
</style>
